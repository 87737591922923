<template>
  <div class="page-content mb-1">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Admin Management" class="mb-2" />
      </b-col>
    </b-row>
    <b-tabs content-class="pt-5" justified v-model="tab">
      <!-- users -->
      <b-tab>
        <template #title>
          <feather-icon class="h4" icon="UsersIcon" />
          <span class="h4">Users</span>
        </template>
        <b-row align-v="center">
          <b-col>
            <form-generator :model="user_query" :schema="user_filter_schema" />
          </b-col>
          <b-col md="2 text-right ml-auto mt-1">
            <data-create-popup :schema="user_create_schema"
              :default-data="{ email: null, language: 'vi', roles: [] }"
              :create-data-fn="createUser"
              @create="$refs.admin_user_list.getList()"
            />
          </b-col>
        </b-row>
        <data-table-ssr id="admin_user_list" ref="admin_user_list" :limit-base="9"
          pagination
          :get-list-fn="getList" :columns="user_fields"
          :edit-row-fn="updateUser" :delete-row-fn="deleteUser"
        />

      </b-tab>

      <!-- roles -->
      <b-tab>
        <template #title>
          <feather-icon class="h4" icon="KeyIcon" />
          <span class="h4">Roles</span>
        </template>
        <b-row class="mb-1">
          <b-col md="2 text-right ml-auto">
            <data-create-popup :schema="role_create_schema" :default-data="{ name: null, key: null }"
              :create-data-fn="createRole"
              @create="$refs.admin_role_list.getList()"
            />
          </b-col>
        </b-row>
        <data-table-ssr id="admin_role_list" ref="admin_role_list" :limit-base="36"
          :get-list-fn="getRoles" :columns="role_fields"
          :delete-row-fn="deleteRole" :edit-row-fn="updateRole" :to-edit-fn="openRoleEdit"
        />
        <RoleEditPopup ref="role_popup" />
      </b-tab>
      <!-- roles-end -->

      <!-- privileges -->
      <b-tab>
        <template #title>
          <feather-icon class="h4" icon="LockIcon" />
          <span class="h4">Privileges</span>
        </template>
        <b-row class="mb-1">
          <b-col md="2 text-right ml-auto">
            <data-create-popup :schema="privilege_create_schema" :default-data="{ key: null }"
              :create-data-fn="createPrivilege"
              @create="$refs.admin_privilege_list.getList()"
            />
          </b-col>
        </b-row>
        <data-table-ssr id="admin_privilege_list" ref="admin_privilege_list" :limit-base="18"
          :get-list-fn="getPrivileges" :columns="privilege_fields"
          :delete-row-fn="deletePrivilege"
        />
      </b-tab>
      <!-- privileges-end -->
    </b-tabs>
    <!-- users-end -->
  </div>
</template>

<script>
import { omitBy } from 'lodash';

import service from "../service";
import roleService from "../service_role";
import privilegeService from "../service_privilege";

import RoleEditPopup from './components/RoleEditPopup.vue';

const user_roles_options = [];
const user_filter_schema = [
  { fields: [{ label: 'Email', field: 'email' }] },
  { fields: [{ label: 'Role', field: 'roles', input_type: 'select', options: user_roles_options }] },
];
const user_fields = [
  { label: 'Email', field: 'email' },
  { label: 'Role', field: 'roles', editable: true, type: 'array', input_type: 'multiselect', options: user_roles_options },
  { label: 'Added At', field: 'createdAt', input_type: 'date' },
];
const user_create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Email', field: 'email', input_type: 'text', validate: { required: true } },
      { label: 'Role', field: 'roles', input_type: 'multiselect', options: user_roles_options },
    ]
  }
];

const role_fields = [
  { label: 'Name', field: 'name', editable: true },
  { label: 'Key', field: 'key' },
  { label: 'Created At', field: 'createdAt', type: 'datetime' },
];
const role_create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true } },
      { label: 'Key', field: 'key', input_type: 'text', validate: { required: true } },
    ]
  }
];

const privilege_create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Key', field: 'key', input_type: 'text', validate: { required: true } },
    ]
  }
];
const privilege_fields = [
  { label: 'Key', field: 'key' },
  { label: 'Created At', field: 'createdAt', type: 'datetime' },
];

export default {
  components: {
    RoleEditPopup,
  },
  data() {
    return {
      user_fields,
      user_filter_schema,
      user_create_schema,
      role_fields,
      role_create_schema,
      privilege_fields,
      privilege_create_schema,
      user_query: {},
    };
  },
  computed: {
    tab: {
      get() {
        return this.$route.query.tab ? Number(this.$route.query.tab) : 0;
      },
      set(value) {
        this.$router.replace({ path: this.$route.path, query: {...this.$route.query, tab: value} });
      }
    },
  },
  watch: {
    user_query: {
      handler() {
        this.$refs.admin_user_list.getList();
      },
      deep: true,
    },
  },
  created() {
    this.getAllRoles();
  },
  methods: {
    async createUser(data) {
      let new_item = await service.create(data);
      return new_item;
    },
    async getList({ limit, page }) {
      let query = omitBy(this.user_query, v => v === null || v === '');
      if(query.email) {
        query.email = { $regex: `^${query.email}` };
      }
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async updateUser(row) {
      await service.update({ data: JSON.stringify(row) });
    },
    async deleteUser({ _id }) {
      await service.delete({ id: _id });
      this.$refs.admin_user_list.getList();
    },
    async updateRole(row) {
      await roleService.update({ data: JSON.stringify(row) });
    },
    async createRole(data) {
      let new_item = await roleService.create(data);
      return new_item;
    },
    async getRoles({ limit, page, query }) {
      let response = await roleService.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async getAllRoles() {
      let response = await roleService.getList({});
      if (response.data.type === "DATA") {
        let list = response.data.data.list;
        list.forEach(item => {
          user_roles_options.push({
            text: item.name,
            value: item.key,
          });
        })
      }
    },
    openRoleEdit(row) {
      this.$refs.role_popup.startEdit(row);
    },
    async deleteRole({_id}) {
      await roleService.delete({id: _id});
      this.$refs.admin_role_list.getList();
      this.getAllRoles();
    },
    async getPrivileges({ limit, page, query }) {
      let response = await privilegeService.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
    async createPrivilege(data) {
      let new_item = await privilegeService.create(data);
      return new_item;
    },
    async deletePrivilege({ _id }) {
      await privilegeService.delete({id: _id});
      this.$refs.admin_privilege_list.getList();
    },
  },
};
</script>
