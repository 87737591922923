<template>
  <div>
    <b-modal v-if="item" :title="title" hide-footer size="lg" modal-class="modal-primary" :no-close-on-backdrop="false"
      v-model="isModalShow"
    >
      <!-- content -->
      <div>
        <b-card no-body>
          <b-card-body>
            <b-card-title>Privileges</b-card-title>
            <b-card-sub-title>Permissions according to <b>'{{ item.name }}'</b></b-card-sub-title>
          </b-card-body>
          <b-table striped responsive :items="table_data" :fields="table_fields" class="mb-0">
            <template #cell(privilege)="data">
              {{ data.item.privilege }}
            </template>
            <template #cell()="data">
              <b-form-checkbox v-model="data.item[data.field.key]" :disabled="isAdminRole" @change="updateStatus($event, data)" />
            </template>
          </b-table>
        </b-card>
      </div>
      <!-- end content -->
      <div class="d-flex justify-content-between my-2">
        <b-button variant="secondary" @click="cancel">
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancel</span>
        </b-button>
        <b-button type="submit" variant="success" :disabled="isAdminRole" @click="update">
          <b-spinner v-if="isLoading" small />
          <feather-icon v-else icon="CheckIcon" />
          <span class="align-middle ml-50">Update</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from './../../service_role';
import privilegeService from './../../service_privilege';
import { safeGet } from '@core/utils';

const table_fields = [
  { label: 'Privilege', key: 'privilege' },
  { label: 'Read', key: 'read' },
  { label: 'Write', key: 'write' },
  { label: 'All', key: 'all' },
];

export default {
  props: {
    'updateDataFn': { type: Function, default: null },
    title: { type: String, default: 'Update Data' },
  },
  data() {
    return {
      table_fields,
      isLoading: false,
      isModalShow: false,
      item: null,
      role_permissions: [],
      permissions: [],
      table_data: [],
    }
  },
  computed: {
    isAdminRole() {
      let role = this.item ? this.item.key : null;
      return role === 'admin';
    },
  },
  methods: {
    startEdit(defaultData) {
      this.item = JSON.parse(JSON.stringify(defaultData));
      this.getPermissions();
      this.$nextTick(() => {
        this.isModalShow = true;
      })
    },
    async getPermissions() {
      this.table_data = [];
      let role = this.item.key;
      let response = await service.getPrivileges({ role });
      let role_permissions = safeGet(() => response.data.privileges) || [];

      let privilege_res = await privilegeService.getList({});
      let permissions = safeGet(() => privilege_res.data.data.list) || [];
      permissions.forEach(item => {
        let item_data = { role, privilege: item.key, read: false, write: false, all: false };
        if(this.isAdminRole) {
          item_data.read = true;
          item_data.write = true;
          item_data.all = true;
        } else {
          let existed_item = role_permissions.find(permission => permission.privilege === item.key);
          if(existed_item) {
            item_data.read = existed_item.read;
            item_data.write = existed_item.write;
            item_data.all = existed_item.all;
          }
        }
        this.table_data.push(item_data);
      });
    },
    updateStatus(checked, { index, field }) {
      let row = this.table_data[index];
      let action = field.key;
      if (checked) {
        if(action === 'all') {
          row.write = true;
          row.read = true;
        } else if (action === 'write') {
          row.read = true
        }
      } else {
        if(action === 'all') {
          row.write = false;
          row.read = false;
        } else if (action === 'write') {
          row.all = false;
        } else if (action === 'read') {
          row.all = false;
          row.write = false;
        }
      }
    },
    async update() {
      this.isLoading = true;
      let privileges = this.table_data;
      await service.updatePrivileges({ privileges });
      this.isLoading = false;
      this.$emit("updated");
      this.cancel();
    },
    cancel() {
      this.isModalShow = false;
      this.item = {};
    },

  }
}
</script>
